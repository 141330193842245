import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VLayout,{staticClass:"d-block"},[_c(VRow,{staticClass:"mb-12"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"card-hover fill-height",attrs:{"outlined":""}},[_c(VAppBar,{attrs:{"elevation":"0","flat":""}},[_c('h2',[_vm._v(_vm._s(_vm.$t("locations.titles.extraData")))]),_c(VSpacer),_c(VImg,{staticClass:"mx-4",attrs:{"src":require('@/assets/icons/firstline.svg'),"contain":"","height":"25px","max-height":"25px","width":"25px","max-width":"25px"}})],1),_c(VCardText,[(_vm.action === 'show')?_c(VRow,[_c(VCol,{staticClass:"ma-0 pa-0 my-2 px-4",attrs:{"cols":"6"}},[_c('div',{staticStyle:{"display":"flex","border-radius":".2em","background-color":"rgb(250, 250, 250)","padding":"20px"}},[_c('h3',{staticClass:"mr-2 font-weight-bold"},[_vm._v(_vm._s(_vm.$t("locations.fields.classificationType"))+":")]),_vm._v(" "+_vm._s(_vm.findClassification(_vm.item))+" ")])])],1):_vm._e(),(['new', 'edit'].includes(_vm.action))?_c(VForm,{ref:"formMain"},[_c(VRow,[_c(VCol,{staticClass:"ma-0 pa-0 my-2 px-4",attrs:{"cols":"4"}},[_c('span',{staticStyle:{"display":"flex","border-radius":".2em","background-color":"rgb(250, 250, 250)","padding":"20px"}},[_c(VSelect,{staticClass:"required",attrs:{"items":_vm.classifications,"label":_vm.$t('locations.fields.classificationType'),"item-value":"id","item-text":"name","item-color":"secondary","color":"secondary","required":"","dense":"","outlined":"","clearable":""},model:{value:(_vm.item.classificationType),callback:function ($$v) {_vm.$set(_vm.item, "classificationType", $$v)},expression:"item.classificationType"}})],1)])],1)],1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }