<template>
  <v-layout class="d-block">
    <v-row class="mb-12">
      <v-col cols="6">
        <v-card outlined class="fill-height card-hover">
          <v-app-bar elevation="0" flat>
            <h2>{{ $t('locations.titles.mainInformation') }}</h2>
            <v-spacer></v-spacer>
            <v-img class="mx-4" :src="require('@/assets/icons/firstline.svg')" contain height="25px" max-height="25px" width="25px" max-width="25px" />
          </v-app-bar>
          <v-card-text>
            <v-row v-if="action === 'show'">
              <v-col cols="6" class="ma-0 pa-0 my-2 px-4">
                <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.storage_type") }}:</h2>
                    {{ item.storage_device_type_id ? item.storage_device_type_id.storage_type_name : '-' }}
                  </span>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0 my-2 px-4">
                <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.name") }}:</h2>
                    {{ item.simpleName || '-' }}
                  </span>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0 my-2 px-4">
                <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                      <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.identificationType") }}:</h2>
                      {{ item.document_key || '-' }}
                    </span>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0 my-2 px-4">
                <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                      <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.identificationValue") }}:</h2>
                      {{ item.document_value || '-' }}
                    </span>
              </v-col>
            </v-row>
            <v-form v-if="['new', 'edit'].includes(action)" ref="formMain" v-model="valid.main">
              <v-row>
                <v-col cols="12" class="ma-0 pa-0 my-2 px-4">
                  <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                      <v-combobox
                        v-model="item.storage_device_type_id"
                        :clearable="true"
                        :hide-selected="true"
                        :items="storageDeviceTypes"
                        :label="$t('locations.fields.storage_type')"
                        :rules="requireRules"
                        class="required"
                        color="secondary"
                        item-text="storage_type_name"
                        persistent-hint
                        small-chips
                        dense
                        outlined
                        :disabled="isVersion"
                      >
                        <template v-slot:label>
                          {{ $t("locations.fields.storage_type") }}
                          <strong class="red--text">*</strong>
                        </template>
                      </v-combobox>
                    </span>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0 my-2 px-4">
                  <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <v-text-field
                      v-model="item.simpleName"
                      :counter="max"
                      :rules="requireAndMaxRules(max)"
                      class="required"
                      color="secondary"
                      maxlength="max"
                      onkeypress="return (event.charCode != 34)"
                      required
                      dense
                      outlined
                      v-on:keyup.86="replace"
                      v-on:keyup.ctrl.86="replace"
                      :disabled="isVersion"
                    >
                      <template v-slot:label>
                        {{ $t("locations.fields.name") }}
                        <strong class="red--text">*</strong>
                      </template>
                    </v-text-field>
                  </span>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0 my-2 px-4">
                  <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <v-text-field
                      v-model="item.document_key"
                      :rules="requireRules"
                      class="required"
                      color="secondary"
                      onkeypress="return (event.charCode != 34)"
                      required
                      dense
                      outlined
                      v-on:keyup.86="replace"
                      v-on:keyup.ctrl.86="replace"
                    >
                      <template v-slot:label>
                        {{ $t("company.fields.identificationType") }}
                        <strong class="red--text">*</strong>
                      </template>
                    </v-text-field>
                  </span>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0 my-2 px-4">
                  <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <v-text-field
                      v-model="item.document_value"
                      :rules="requireRules"
                      class="required"
                      color="secondary"
                      onkeypress="return (event.charCode != 34)"
                      required
                      dense
                      outlined
                      v-on:keyup.86="replace"
                      v-on:keyup.ctrl.86="replace"
                    >
                      <template v-slot:label>
                        {{ $t("company.fields.identificationValue") }}
                        <strong class="red--text">*</strong>
                      </template>
                    </v-text-field>
                  </span>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card outlined class="fill-height card-hover">
          <v-app-bar elevation="0" flat>
            <h2>{{ $t('locations.titles.secondaryInformation') }}</h2>
            <v-spacer></v-spacer>
            <v-img class="mx-4" :src="require('@/assets/icons/firstline.svg')" contain height="25px" max-height="25px" width="25px" max-width="25px" />
          </v-app-bar>
          <v-card-text>
            <v-row v-if="action === 'show'">
              <v-col cols="6">
                <div class="card__items">
                  <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.telephone") }}:</h2>
                    {{ item.telephone || '-' }}
                  </span>
                  <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.email") }}:</h2>
                    {{ item.email || '-' }}
                  </span>
                  <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.gln") }}:</h2>
                    {{ item.gln || '-' }}
                  </span>
                  <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t('locations.fields.commercialSite') }}:</h2>
                    {{ item.comercialSite || '-' }}
                  </span>
                </div>
              </v-col>
              <v-col cols="12">
                <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                  <h2 class="mr-2 font-weight-bold">{{ $t("modules.name.PersonInCharge") }}:</h2>
                  {{ responsavel.join(', ') }}
                </span>
              </v-col>
            </v-row>
            <v-form v-if="['new', 'edit'].includes(action)" ref="formSecondary" v-model="valid.secondary">
              <v-row>
                <v-col cols="6">
                  <div class="card__items">
                    <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                      <v-text-field
                        v-model="item.telephone"
                        v-mask="phoneMask"
                        placeholder="+xx (xx) xxxxx-xxxx"
                        :label="$t('locations.fields.telephone')"
                        color="secondary"
                        dense
                        outlined
                        :rules="phoneRules"
                        @input="updateMask(item.telephone)"
                      >
                      </v-text-field>
                    </span>
                    <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                      <v-text-field
                        v-model="item.email"
                        :label="$t('locations.fields.email')"
                        color="secondary"
                        dense
                        outlined
                        :rules="emailRules"
                        onkeypress="return (event.charCode != 34)"
                        v-on:keyup.86="replace"
                        v-on:keyup.ctrl.86="replace"
                      />
                    </span>
                    <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                      <v-text-field
                        v-model="item.gln"
                        :counter="maxGLN"
                        :rules="
                          existingElementGlnSecondary(maxGLN, messageValidGln)
                        "
                        dense
                        outlined
                        color="secondary"
                        :maxlength="maxGLN"
                        onkeypress="return (event.charCode != 34)"
                        @input="checkGln()"
                        v-on:keyup.86="replace"
                        v-on:keyup.ctrl.86="replace"
                        :disabled="isVersion"
                      >
                        <template v-slot:label>
                          {{ $t("storage.fields.gln") }}
                          <strong class="red--text">*</strong>
                        </template>
                      </v-text-field>
                    </span>
                    <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                      <v-text-field
                        dense
                        outlined
                        v-model="item.comercialSite"
                        :label="$t('locations.fields.commercialSite')"
                        color="secondary"
                      >
                      </v-text-field>
                    </span>
                  </div>
                </v-col>
                <v-col cols="12">
                  <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <v-combobox
                      v-model="item.personsInCharge"
                      :clearable="true"
                      :items="authorities"
                      :no-data-text="$t('general.fields.noDataAvailable')"
                      :rules="requireRules"
                      class="required ma-0 pa-0"
                      color="secondary"
                      item-text="name"
                      item-value="id"
                      multiple
                      persistent-hint
                      return-object
                      small-chips
                      dense
                      outlined
                      item-color="secondary"
                      :disabled="!checkAdmin()"
                    >
                      <template v-slot:label>
                        {{ $t("locations.fields.personInCharge") }}
                        <strong class="red--text">*</strong>
                      </template>
                      <template v-slot:selection="{ item:user, index }">
                        <v-chip small v-if="index === 0">
                          <span>{{ user.name }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text caption"
                          >(+{{
                            item.personsInCharge.length - 1
                          }}
                          {{ $t('general.titles.others') }})</span
                        >
                      </template>
                    </v-combobox>
                  </span>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card outlined class="fill-height card-hover">
            <v-app-bar elevation="0" flat>
            <h2>{{ $t('locations.titles.addressInformation') }}</h2>
            <v-spacer></v-spacer>
              <v-img class="mx-4" :src="require('@/assets/icons/firstline.svg')" contain height="25px" max-height="25px" width="25px" max-width="25px" />
          </v-app-bar>
          <v-card-text>
            <v-row v-if="action === 'show'">
              <v-col cols="6">
                <div class="card__items">
                  <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.address1") }}:</h2>
                    {{ item.address1 || '-' }}
                  </span>
                  <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.address2") }}:</h2>
                    {{ item.address2 || '-' }}
                  </span>
                  <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.state") }}:</h2>
                    {{ item.state || '-' }}
                  </span>
                  <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.city") }}:</h2>
                    {{ item.city || '-' }}
                  </span>
                  <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.country") }}:</h2>
                    {{ item.country || '-' }}
                  </span>
                  <span style="display: flex; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="mr-2 font-weight-bold">{{ $t("locations.fields.zipCode") }}:</h2>
                    {{ item.zipCode || '-' }}
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-form v-if="['new', 'edit'].includes(action)" ref="formAddress" v-model="valid.address">
              <address-form-component
                v-if="['new', 'edit'].includes(action) && isReady"
                :fieldProps="fieldPropsAddress"
                :item="item"
              />
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card outlined class="fill-height card-hover">
          <v-app-bar elevation="0" flat>
            <h2>{{ $t('locations.titles.extraInformation') }}</h2>
            <v-spacer></v-spacer>
            <v-img class="mx-4" :src="require('@/assets/icons/firstline.svg')" contain height="25px" max-height="25px" width="25px" max-width="25px" />
          </v-app-bar>
          <v-card-text>
            <v-row v-if="action === 'show'">
              <v-col cols="12">
                <div class="card__items">
                  <span style="display: block; border-radius: .2em; background-color: rgb(250, 250, 250); padding: 20px;">
                    <h2 class="font-weight-bold">{{ $t("locations.fields.description") }}:</h2>
                    <div style="height: 100px; overflow: auto">
                      <read-more-component
                              :classes="'mb-0'"
                              :text="translate(item.description) ||'-'"
                              :max-chars="300"
                              :font-size="14"
                      />
                    </div>
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-form v-if="['new', 'edit'].includes(action)" ref="formExtras" v-model="valid.extras">
              <v-row>
                <v-col cols="12">
                  <div class="card__items">
                    <span
                      style="
                        display: flex;
                        border-radius: 0.2em;
                        background-color: rgb(250, 250, 250);
                        padding: 20px;
                      "
                    >
                    <v-textarea
                      v-model="item.description_text"
                      :auto-grow="false"
                      :clearable="false"
                      :counter="3000"
                      :label="$t('locations.fields.description')"
                      :no-resize="true"
                      :row-height="40"
                      :rows="7"
                      :rules="lengthRules(3000).concat(limitEnterKeyPress)"
                      color="secondary"
                      onkeypress="return (event.charCode != 34)"
                      v-on:keyup.86="replace"
                      v-on:keyup.ctrl.86="replace"
                        dense
                        outlined
                    />
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
 import {mapGetters} from "vuex";
 import LanguageService from "@/services/LanguajeService";
 import LocationsService from "@/services/Locations";
 import _ from "lodash";
 import PermissionService from "@/services/PermisionService";
 import ReadMoreComponent from '@/components/ReadMoreComponent.vue';
 import AddressFormComponent from "@/module/configuration/location/components/common/AddressFormComponent.vue"

 export default {
  components: { AddressFormComponent, ReadMoreComponent },
   name: "TabMainLocation",
    props: {
      item: {
        type: Object,
        required: true
      },
      action: {
        type: String,
        required: false,
        default: "show"
      },
      isReady: {
        type: Boolean,
        required: false,
        default: false
      }
    },

   data: () => ({
     isVersion: false,
     valid: {
       main: false,
       secondary: false,
       address: false,
       extras: false
     },
     max: 40,
     maxGLN: 18,
     writingTime: null,
     messageValidGln: "",
     phoneMask: "+55 (##) #####-####",
     fieldPropsAddress: {
       address1: { props: { color: 'secondary', outlined: true, dense: true, required: true }},
       address2: { props: { color: 'secondary', outlined: true, dense: true }},
       country: { props: { color: 'secondary', outlined: true, dense: true, itemColor: 'secondary', required: true, returnObject: true }},
       state: { props: { color: 'secondary', outlined: true, dense: true, itemColor: 'secondary', required: true, returnObject: true }},
       city: { props: { color: 'secondary', outlined: true, dense: true, itemColor: 'secondary', required: true, returnObject: true }},
       zipCode: { props: { color: 'secondary', outlined: true, dense: true, required: true }}
     }
   }),

   watch: {
    valid: {
      handler() {
          this.$emit('update:validation', this.valid.main && this.valid.secondary && this.valid.address && this.valid.extras)
      },
      deep: true
    }
   },

   computed: {
     ...mapGetters({
       locations: 'locations/locationsList',
        requireRules: "general/requireRules",
        requireAndMaxRules: "general/requireAndMaxRules",
        existingElementGln: "general/existingElementGln",
        authorities: "authority/authoritiesForLocations",
        lengthRules: "general/lengthRules",
        emailRules: "general/emailRules",
        onlyNumberFormat: "general/onlyNumberFormat",
        phoneRules: "general/phoneRules",
        limitEnterKeyPress: "general/limitEnterKeyPress",
        countryList: "general/countryList",
        storageDeviceTypes: "storage_type/storageDeviceTypesActiveByCompany",
        existingElementGlnSecondary: "general/existingElementGlnSecondary",
     }),
     responsavel() {
      return this.item.personsInCharge && this.item.personsInCharge.length > 0 ? _.map(this.item.personsInCharge, p => p.name) : '-';
     }
   },

   created() {
     this.isVersion = JSON.parse(localStorage.getItem("location:isVersion")) === 1 ;
   },

   methods: {
     // Check permission Admin
     checkAdmin() {
       return PermissionService.checkAdmin()
     },
     updateMask(value) {
       if (value && value.length >= 6 && value[5] !== '(') {
         this.phoneMask = '+55 (##) #####-####';
       } else {
         this.phoneMask = '+55 (##) ####-####';
       }
     },
     translate(key, uppercase) {
        return LanguageService.getKey3(key)
      },
      async checkGln() {
        this.messageValidGln = ""
        if (this.writingTime !== null) clearTimeout(this.writingTime)
        this.writingTime = setTimeout(async x => {
          try {
            await LocationsService.checkGln(this.item.gln).then(() => {
              this.messageValidGln = ""
            })
          } catch (error) {
            if (error.response.status === 406) {
              this.messageValidGln = error.response.data.message
            }
          }
        }, 1000)
      },
      replace(event) {
        event.currentTarget.value = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
      },
   }
 }
</script>


<style scoped>
.fill-icon__color {
  filter: invert(20%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(400%) contrast(88%);
}

.card-hover {
  box-shadow: none;
  transition: all 200ms ease-in-out;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }
}

.card__items {
  display: grid;
  grid-template-columns: 100% 100%;
  gap: 14px
}
</style>